import axios from "axios";
export default {
  
  /**
   * get 请求
   * @param url 接口路由
   * @param auth 是否需要带登录信息
   * @returns {AxiosPromise<any>}
   */
  get(url,data, auth = false) {
    if (auth) {
      return axios.get(url, {
        params:data,
        headers: {
          "Token": localStorage.getItem('token'),
          "lang-type":localStorage.getItem('erp_lang') || "zh-cn"
        },
      });
    } else {
      return axios.get(url);
    }
  },

  /**
   * post 请求
   *
   * @param url 接口路由
   * @param data 接口参数
   * @param auth 是否需要带登录信息
   * @returns {AxiosPromise<any>}
   */
  post(url, data, auth = false) {
    
    if (auth) {
      return axios.post(url, data, {
        headers: {
          "Token": localStorage.getItem('token'),
          "lang-type":localStorage.getItem('erp_lang') || "zh-cn"
        },
      });
    } else {
      return axios.post(url, data);
    }
  },

  /**
   * put请求
   * @param url 接口路由
   * @param data 接口参数
   * @param auth 是否需要带登录信息
   * @returns {AxiosPromise<any>}
   */
  put(url, data, auth = false) {
    if (auth) {
      return axios.put(url, data, {
        headers: {
          "Token": localStorage.getItem('token'),
          "lang-type":localStorage.getItem('erp_lang') || "zh-cn"
        },
      });
    } else {
      return axios.put(url, data);
    }
  },

  /**
   * 删除
   * @param url 接口路由
   * @param auth 是否需要带登录信息
   * @returns {AxiosPromise}
   */
  del(url, auth = false) {
    if (auth) {
      return axios.delete(url, {
        headers: {
          "Token": localStorage.getItem('token'),
          "lang-type":localStorage.getItem('erp_lang') || "zh-cn"
        },
      });
    } else {
      return axios.delete(url);
    }
  },

  /**
   * 上传文件
   * @param url 接口路由
   * @param file 接口文件
   * @param auth 是否需要带登录信息
   */
  uploader(url, file, auth = false) {
    let param = new FormData();
    param.append("file", file);
    if (auth) {
      return axios.post(url, param, {
        headers: {
          "Token": localStorage.getItem('token'),
          "lang-type":localStorage.getItem('erp_lang') || "zh-cn"
        },
      });
    } else {
      return axios.post(url, param);
    }
  },
};
