<script setup>
import { ref, reactive, getCurrentInstance, onMounted, watch } from "vue";
import {  } from "element-plus";
import { ElMessage, FormRules, ElMessageBox } from "element-plus";
import { useRouter, useRoute } from "vue-router";
const { proxy } = getCurrentInstance();
const router = useRouter();
const route = useRoute();
const isCollapse = ref(false);
const circleUrl =
  "https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png";

// let userInfo = JSON.parse(localStorage.getItem('bs_user_info'))
let erp_lang = ref("zh-cn");
let pageData = reactive({
  userInfo:{}
})
let isInitialized = false;

//修改密码表单
let passwordForm = reactive({});
let passwordFormRef = ref();
//修改密码弹窗
let updatePasswordDialogVisible = ref(false);
//修改密码验证规则
var validatePass = (rule, value, callback) => {
  if (value === "") {
    callback(new Error("请再次输入密码"));
  } else if (value !== passwordForm.new_password) {
    callback(new Error("两次输入密码不一致!"));
  } else {
    callback();
  }
};
//修改密码，表单验证
const passwordrules =
  reactive <
  FormRules >
  {
    old_password: [
      { required: true, message: "原始密码不能为空", trigger: "blur" },
      { min: 6, message: "原始密码长度不能少于6位", trigger: "blur" },
    ],
    new_password: [
      { required: true, message: "新密码不能为空", trigger: "blur" },
      { min: 6, message: "新密码长度不能少于6位", trigger: "blur" },
    ],
    confirm_password: [
      { required: true, message: "确认密码不能为空", trigger: "blur" },
      { min: 6, message: "确认密码长度不能少于6位", trigger: "blur" },
      { validator: validatePass, trigger: "blur" },
    ],
  };
//提交修改密码
const updatePassword = async () => {
  passwordFormRef.value.validate((valid, fields) => {
    if (valid) {
      proxy.$http
        .post(proxy.$api.Admin.UpdatePassword, passwordForm, true)
        .then((res) => {
          if (res.status === 1) {
            updatePasswordDialogVisible.value = false;
            ElMessage({
              message: res.message,
              type: "success",
            });
          } else {
            ElMessage({
              message: res.message,
              type: "error",
            });
          }
        });
    } else {
      console.log("error submit!", fields);
    }
  });
};

//退出登陆
const logout = () => {
  ElMessageBox.confirm("您即将退出登陆？", "警告", {
    confirmButtonText: "登出",
    cancelButtonText: "取消",
    type: "warning",
  }).then(() => {
    localStorage.clear();
    proxy.$router.push({ name: "Login" });
  });
};

const tabs = ref([]);
const getBreadCom = () => {
  let mached = route.matched.filter((item) => item.meta.title);
  
  // 第一个路径如果不是首页，需要在匹配路径前面拼接一个首页
  const first = mached[0] || [];

  if (first.path != "/console") {
    mached = [{ path: "/console", meta: { title: "控制台",en_title: "Console" } }].concat(mached);
  }
  //设置面包屑的数据
  tabs.value = mached;
};

const saveLanguage = (newLang) => {
  if (!isInitialized) return;
  console.log(erp_lang.value);
  console.log(newLang);
  localStorage.setItem("erp_lang", erp_lang.value);
};

onMounted(() => {
  pageData.userInfo = JSON.parse(localStorage.getItem("bs_user_info"))
  erp_lang.value = localStorage.getItem("erp_lang") || "zh-cn";
  isInitialized = true;
});

watch(router.currentRoute, () => {
  getBreadCom();
});
</script>

<template>
  <div>
    <div v-if="$route.meta.showCommon">
      <el-container>
        <el-aside width="275px">
          <div class="sidebar-logo-container">
            <a href="/" class="sidebar-logo-link router-link-active">
              <img src="./assets/logo.png" class="sidebar-logo-big" />
            </a>
          </div>
          <el-menu
            default-active="goods"
            class="el-menu-vertical-demo"
            style="--el-menu-active-color: #fff;width: 100%;"
            background-color="#0b1529"
            text-color="#ffffff"
            router="true"
            :collapse="isCollapse"
            :unique-opened="true"
          >
            <el-menu-item index="/console/console_index">
              <el-icon><DataAnalysis /></el-icon>
              <span>{{ $t('menu.menu_console') }}</span>
            </el-menu-item>
            <el-sub-menu index="/goods" v-has="1">
              <template #title>
                <el-icon><Goods /></el-icon>
                <span>{{ $t('menu.menu_merchandise_management') }}</span>
              </template>
              <el-menu-item index="/goods/list" v-has="2">{{ $t('menu.menu_item_product_list') }}</el-menu-item>
              <el-menu-item index="/goods/category" v-has="7">{{ $t('menu.menu_item_product_type') }}</el-menu-item>
              <el-menu-item index="/goods/activities" v-has="14">{{ $t('menu.menu_item_activity_list') }}</el-menu-item>
            </el-sub-menu>
            <el-sub-menu index="/warehouse" v-has="19">
              <template #title>
                <el-icon><Suitcase /></el-icon>
                <span>{{ $t('menu.menu_warehouse_management') }}</span>
              </template>
              <el-menu-item index="/warehouse/warehouse_list" v-has="20"
                >{{ $t('menu.menu_item_warehouse_list') }}</el-menu-item
              >
              <el-menu-item index="/warehouse/receipt_list" v-has="24"
                >{{ $t('menu.menu_item_inbound_management') }}</el-menu-item
              >
              <el-menu-item index="/warehouse/outbound_list" v-has="29"
                >{{ $t('menu.menu_item_outbound_management') }}</el-menu-item
              >
              <el-menu-item index="/warehouse/allot_list" v-has="34"
                >{{ $t('menu.menu_item_transfer_order_management') }}</el-menu-item
              >
              <el-menu-item index="/goods/warning" v-has="84">{{ $t('menu.menu_item_product_alerts') }}</el-menu-item>
              <!-- <el-menu-item index="/warehouse/warehouse_goods" v-has="85">商品仓库</el-menu-item> -->

            </el-sub-menu>
            <el-sub-menu index="/finance" v-has="39">
              <template #title>
                <el-icon><Postcard /></el-icon>
                <span>{{ $t('menu.menu_financial_management') }}</span>
              </template>
              <el-menu-item index="/finance/reimburse" v-has="40">{{ $t('menu.menu_item_reimbursement_management') }}</el-menu-item>
              <el-menu-item index="/finance/statistics" v-has="49">{{ $t('menu.menu_item_summary') }}</el-menu-item>
              <el-menu-item index="/finance/invoicing_view" v-has="94"
                >{{ $t('menu.menu_item_invoicing') }}</el-menu-item
              >
            </el-sub-menu>
            <el-sub-menu index="/system" v-has="50">
              <template #title>
                <el-icon><Setting /></el-icon>
                <span>{{ $t('menu.menu_system_configuration') }}</span>
              </template>
              <el-menu-item index="/system/basic_sys" v-has="95">{{ $t('menu.menu_item_basic_configuration') }}</el-menu-item>
              <el-menu-item index="/banner_list" v-has="51">{{ $t('menu.menu_item_carousel_list') }}</el-menu-item>
              <el-menu-item index="/manager_list" v-has="57">{{ $t('menu.menu_item_manager_list') }}</el-menu-item>
              <el-menu-item index="/system/credit_rating" v-has="45"
                >{{ $t('menu.menu_item_credit_rating') }}</el-menu-item
              >
              <el-menu-item index="/system/role_management" v-has="62"
                >{{ $t('menu.menu_item_role_list') }}</el-menu-item
              >
              <el-menu-item index="/system/address" v-has="78">{{ $t('menu.menu_item_address_management') }}</el-menu-item>
              <el-menu-item index="/system/order_approval" v-has="66"
                >{{ $t('menu.menu_item_order_approval_flow') }}</el-menu-item
              >
              <el-menu-item index="/system/audit_warehouse_approval" v-has="105"
                >{{ $t('menu.menu_item_warehouse_approval_flow') }}</el-menu-item
              >
              <el-menu-item index="/system/audit_rhyming_burse_approval" v-has="109"
                >{{ $t('menu.menu_item_financial_approval_flow') }}</el-menu-item
              >
              <el-menu-item index="/system/export_records" v-has="111"
                >{{ $t('menu.menu_item_export_record') }}</el-menu-item
              >
            </el-sub-menu>
            <el-sub-menu index="/order" v-has="67">
              <template #title>
                <el-icon><MessageBox /></el-icon>
                <span>{{ $t('menu.menu_order_management') }}</span>
              </template>
              <el-menu-item index="/order/order_list" v-has="71">{{ $t('menu.menu_order_list') }}</el-menu-item>
            </el-sub-menu>
            <el-sub-menu index="/member" v-has="116">
              <template #title>
                <el-icon><User /></el-icon>
                <span>{{ $t('menu.menu_member_management') }}</span>
              </template>
              <el-menu-item index="/member/member_list" v-has="116">{{ $t('menu.menu_member_list') }}</el-menu-item>
            </el-sub-menu>
          </el-menu>
        </el-aside>
        <el-container>
          <el-header>
            <div
              style="
                height: 55px;
                padding: 5px;
                overflow: hidden;
                position: relative;
                background: #fff;
                -webkit-box-shadow: 0 1px 4px rgb(0 21 41 / 8%);
                box-shadow: 0 1px 4px rgb(0 21 41 / 8%);
                display: flex;
                align-items: center;
                justify-content: space-between;
              "
            >
              <div>
                <el-breadcrumb separator="/">
                  <el-breadcrumb-item
                    v-for="item in tabs"
                    :to="item.path"
                    :key="item.meta.title"
                    >{{ erp_lang =='zh-cn' ? item.meta.title :item.meta.en_title }}</el-breadcrumb-item
                  >
                </el-breadcrumb>
              </div>
              <div>
                <el-space>
                  <span style="margin-right: 10px">
                    <el-switch
                      v-model="erp_lang"
                      class="ml-2"
                      inline-prompt
                      style="
                        --el-switch-on-color: #13ce66;
                        --el-switch-off-color: #ff4949;
                      "
                      active-text="中文"
                      active-value="zh-cn"
                      inactive-text="英文"
                      inactive-value="en-us"
                      @change="saveLanguage"
                    />
                  </span>
                  <el-avatar :src="circleUrl" fit="cover" shape="square" />
                  <!-- <el-avatar> 氢祥 </el-avatar> -->
                  <el-dropdown>
                    <span class="el-dropdown-link">
                      <spa>{{pageData.userInfo.name}}</spa>
                      <el-icon class="el-icon--right">
                        <arrow-down />
                      </el-icon>
                    </span>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <!-- <el-dropdown-item
                          @click="updatePasswordDialogVisible = true"
                          >修改密码</el-dropdown-item
                        > -->
                        <el-dropdown-item @click="logout"
                          >Exit</el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </el-space>
              </div>
            </div>
          </el-header>
          <el-main
            style="height: calc(100vh - 160px); background-color: #f0f2f5"
            ><router-view></router-view
          ></el-main>
          <!-- 修改密码 -->
          <el-dialog
            v-model="updatePasswordDialogVisible"
            title="修改密码"
            width="550px"
            align-center
          >
            <el-form
              :model="passwordForm"
              label-width="120px"
              :rules="passwordrules"
              ref="passwordFormRef"
            >
              <el-form-item label="原始密码" prop="old_password">
                <el-input v-model="passwordForm.old_password" />
              </el-form-item>
              <el-form-item label="新密码" prop="new_password">
                <el-input v-model="passwordForm.new_password" />
              </el-form-item>
              <el-form-item label="确认密码" prop="confirm_password">
                <el-input v-model="passwordForm.confirm_password" />
              </el-form-item>
            </el-form>
            <template #footer>
              <span class="dialog-footer">
                <el-button @click="updatePasswordDialogVisible = false"
                  >取消</el-button
                >
                <el-button type="primary" @click="updatePassword">
                  编辑
                </el-button>
              </span>
            </template>
          </el-dialog>
        </el-container>
      </el-container>
    </div>
    <div v-if="!$route.meta.showCommon">
      <router-view></router-view>
    </div>
  </div>
</template>

<style>
* {
  padding: 0;
  margin: 0px;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: calc(100vh - 65px);
}
/* .el-header {
  padding: 0 !important;
} */
.sidebar-logo-container {
  position: relative;
  width: 100%;
  height: 65px;
  line-height: 65px;
  text-align: center;
  overflow: hidden;
  background-color: #0b1529;
}
.sidebar-logo-big {
  width: auto;
  height: 50px;
  vertical-align: middle;
  margin-right: 12px;
  display: inline-block;
}
/* 富文本菜单 */
.tox-tinymce-aux {
  z-index: 9999 !important;
}
.el-menu-item.is-active {
  background-color: #72c232;
}
</style>
