/*
 * @Author: 刘逛 180768871@qq.com
 * @Date: 2023-04-26 10:04:09
 * @LastEditors: 刘逛 180768871@qq.com
 * @LastEditTime: 2024-11-21 12:00:51
 * @FilePath: \admin_pc\src\router\router.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Login",
    component: () => import("@/views/LoginView.vue"),
    meta: {
      title: "登陆",
      en_title: "Landing",
      showCommon: false,
    },
  },
  {
    path: "/goods",
    name: "goods",
    meta: {
      title: "商品管理",
      en_title: "Commodity Management",
      showCommon: true,
    },
    children: [
      {
        path: "list",
        name: "goods_list",
        meta: {
          title: "商品列表",
          en_title: "Commodity List",
        },
        component: () => import("@/views/goods/ListView.vue"),
      },
      {
        path: "create",
        name: "goods_create",
        meta: {
          title: "商品创建",
          en_title: "Commodity Creation",
        },
        component: () => import("@/views/goods/components/CreateView.vue"),
      },
      {
        path: "update/:id",
        name: "goods_update",
        meta: {
          title: "商品编辑",
          en_title: "Commodity Editor",
        },
        component: () => import("@/views/goods/components/CreateView.vue"),
      },
      {
        path: "category",
        name: "category_list",
        meta: {
          title: "商品分类",
          en_title: "Commodity Classification",
        },
        component: () => import("@/views/goods/CategoryView.vue"),
      },
      {
        path: "activities",
        name: "goods_activities",
        meta: {
          title: "活动列表",
          en_title: "Activity List",
        },
        component: () => import("@/views/goods/ActivityListView.vue"),
      },
      {
        path: "warning",
        name: "goods_warning",
        meta: {
          title: "商品预警",
          en_title: "Commodity Alert",
        },
        component: () => import("@/views/goods/WarningListView.vue"),
      },
    ],
  },
  {
    path: "/warehouse",
    name: "warehouse",
    meta: {
      title: "仓库管理",
      en_title: "Warehouse Management",
      showCommon: true,
    },
    children: [
      {
        path: "warehouse_list",
        name: "warehouse_list",
        meta: {
          title: "仓库列表",
          en_title: "Warehouse List",
        },
        component: () => import("@/views/warehouse/ListView.vue"),
      },
      {
        path: "receipt_list",
        name: "receipt_list",
        meta: {
          title: "入库列表",
          en_title: "Receipt List",
        },
        component: () => import("@/views/warehouse/ReceiptView.vue"),
      },
      {
        path: "outbound_list",
        name: "outbound_list",
        meta: {
          title: "出库列表",
          en_title: "Issue List",
        },
        component: () => import("@/views/warehouse/OutboundView.vue"),
      },
      {
        path: "allot_list",
        name: "allot_list",
        meta: {
          title: "调拨单",
          en_title: "Transfer Doc",
        },
        component: () => import("@/views/warehouse/AllotView.vue"),
      },
      {
        path: "warehouse_goods",
        name: "warehouse_goods",
        meta: {
          title: "仓库商品",
          en_title: "Warehouse Goods",
        },
        component: () => import("@/views/warehouse/WarehouseGoodsView.vue"),
      },
    ],
  },
  {
    path: "/finance",
    name: "finance",
    meta: {
      title: "财务管理",
      en_title: "Financial Management",
      showCommon: true,
    },
    children: [
      {
        path: "reimburse",
        name: "reimburse",
        meta: {
          title: "报销列表",
          en_title: "Reimbursement List",
        },
        component: () => import("@/views/finance/ReimburseView.vue"),
      },
      {
        path: "statistics",
        name: "statistics",
        meta: {
          title: "汇总",
          en_title: "Summary",
        },
        component: () => import("@/views/finance/StatisticsView.vue"),
      },
      // {
      //   path: "purchasing_sale_and_deposit",
      //   name: "purchasing_sale_and_deposit",
      //   meta: {
      //     title: "进销存",
      //   },
      //   component: () =>
      //     import("@/views/finance/PurchasingSaleAndDepositView.vue"),
      // },
      // 新改版进销存
      {
        path: "invoicing_view",
        name: "invoicing_view",
        meta: {
          title: "进销存",
          en_title: "Purchase Sale And Deposit",
        },
        component: () => import("@/views/finance/InvoicingView.vue"),
      },
    ],
  },
  {
    path: "/system",
    name: "system",
    meta: {
      title: "系统配置",
      en_title: "System Configuration",
      showCommon: true,
    },
    children: [
      {
        path: "credit_rating",
        name: "credit_rating",
        meta: {
          title: "信用等级",
          en_title: "Credit Rating",
        },
        component: () => import("@/views/system/CreditRatingView.vue"),
      },
      {
        path: "role_management",
        name: "role_management",
        meta: {
          title: "角色列表",
          en_title: "Role List",
        },
        component: () => import("@/views/system/RoleManagementView.vue"),
      },
      {
        path: "permission_management",
        name: "permission_management",
        meta: {
          title: "权限列表",
          en_title: "Permissions List",
        },
        component: () => import("@/views/system/PermissionManagementView.vue"),
      },
      {
        path: "address",
        name: "address",
        meta: {
          title: "地址维护",
          en_title: "Address Maintenance",
        },
        component: () => import("@/views/system/AddressView.vue"),
      },
      {
        path: "order_approval",
        name: "order_approval",
        meta: {
          title: "订单审批流",
          en_title: "Order Approval Flow",
        },
        component: () => import("@/views/system/OrderApproval.vue"),
      },
      {
        path: "audit_warehouse_approval",
        name: "audit_warehouse_approval",
        meta: {
          title: "仓库审批流",
          en_title: "Warehouse Approval Flow",
        },
        component: () => import("@/views/system/AuditWarehouseApproval.vue"),
      },
      {
        path: "audit_rhyming_burse_approval",
        name: "audit_rhyming_burse_approval",
        meta: {
          title: "财务审批流",
          en_title: "Financial Approval Process",
        },
        component: () => import("@/views/system/AuditReimburseApproval.vue"),
      },
      {
        path: "basic_sys",
        name: "basic_sys",
        meta: {
          title: "基础配置",
          en_title: "Basic Configuration",
        },
        component: () => import("@/views/system/BasicSysView.vue"),
      },
      {
        path: "export_records",
        name: "export_records",
        meta: {
          title: "导出记录",
          en_title: "Export Records",
        },
        component: () => import("@/views/system/ExportRecordsView.vue"),
      },
    ],
  },
  {
    path: "/order",
    name: "order",
    meta: {
      title: "订单管理",
      en_title: "Order Management",
      showCommon: true,
    },
    children: [
      {
        path: "order_list",
        name: "order_list",
        meta: {
          title: "订单列表",
          en_title: "Order List",
        },
        component: () => import("@/views/order/ListView.vue"),
      },
    ],
  },
  {
    path: "/member",
    name: "member",
    meta: {
      title: "会员管理",
      en_title: "Member Management",
      showCommon: true,
    },
    children: [
      {
        path: "member_list",
        name: "member_list",
        meta: {
          title: "会员列表",
          en_title: "Member List",
        },
        component: () => import("@/views/member/ListView.vue"),
      },
    ],
  },
  {
    path: "/console",
    name: "console",
    meta: {
      title: "控制台",
      en_title: "Console",
      showCommon: true,
    },
    children: [
      {
        path: "console_index",
        name: "console_index",
        meta: {
          title: "会员列表",
          en_title: "Member List",
        },
        component: () => import("@/views/console/IndexView.vue"),
      },
    ],
  },
  {
    path: "/banner_list",
    name: "banner_list",
    component: () => import("@/views/banner/ListView.vue"),
    meta: {
      title: "轮播图管理",
      en_title: "Carousel Chart Management",
      showCommon: true,
    },
  },
  {
    path: "/manager_list",
    name: "manager_list",
    component: () => import("@/views/manager/ListView.vue"),
    meta: {
      title: "管理员列表",
      en_title: "Administrator List",
      showCommon: true,
    },
  },
];

// 3. 创建路由实例并传递 `routes` 配置。

const router = createRouter({
  // 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
  history: createWebHistory(),
  routes, // `routes: routes` 的缩写
});

export default router;
