/*
 * @Author: 刘逛 180768871@qq.com
 * @Date: 2024-09-02 11:48:40
 * @LastEditors: 刘逛 180768871@qq.com
 * @LastEditTime: 2024-12-04 19:26:42
 * @FilePath: \admin_pc\src\language\zh-CN.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default {
  menu: {
    menu_console: "控制台",
    menu_merchandise_management: "商品管理",
    menu_item_product_list: "商品列表",
    menu_item_product_type: "商品分类",
    menu_item_activity_list: "活动列表",
    menu_warehouse_management: "仓库管理",
    menu_item_warehouse_list: "仓库列表",
    menu_item_inbound_management: "入库管理",
    menu_item_outbound_management: "出库管理",
    menu_item_transfer_order_management: "调拨单管理",
    menu_item_product_alerts: "商品预警",
    menu_financial_management: "财务管理",
    menu_item_reimbursement_management: "报销管理",
    menu_item_summary: "汇总",
    menu_item_invoicing: "进销存",
    menu_system_configuration: "系统配置",
    menu_item_basic_configuration: "基础配置",
    menu_item_carousel_list: "轮播图列表",
    menu_item_manager_list: "管理员列表",
    menu_item_credit_rating: "信用等级",
    menu_item_role_list: "角色列表",
    menu_item_address_management: "地址管理",
    menu_item_order_approval_flow: "订单审批流",
    menu_item_warehouse_approval_flow: "仓库审批流",
    menu_item_financial_approval_flow: "财务审批流",
    menu_item_export_record: "导出记录",
    menu_order_management: "订单管理",
    menu_order_list: "订单列表",
    menu_member_management: "会员管理",
    menu_member_list: "会员列表",
  },
  common: {
    more: "查看更多",
    title: "农药ERP管理系统", //网页标题
    description: "农药ERP管理系统描述", //网页标题
  },
  //登陆表单
  loginForm: {
    title: "登陆",
    username_label: "用户名",
    password_label: "密码",
    btn_name: "提交",
  },
  //公共组件
  commonComponents: {
    create_btn: "创建",
    edit_btn: "编辑",
    del_btn: "删除",
    info_btn: "详情",
    search_btn: "搜索",
    rest_btn: "重置",
    delete_all: "删除所有",
    operation: "操作",
    warning: "警告",
    void_btn: "审核",
    cancel: "取消",
    please_enter_content: "请输入内容...",
    do_not_display: "不显示",
    confirm_delete_prompt: "您确认要删除吗？",
    display: "显示",
    confirm: "确认",
    open: "开启",
    close: "关闭",
    import: "导入",
    bulk_import: "批量导入",
    by: "通过",
    rejected: "驳回",
    remark: "备注",
    to_be_reviewed: "待审核",
    void: "作废",
    submission: "提交",
    in_review: "审核中",
    download: "下载",
    download_the_template: "下载模板",
    in_progress: "进行中",
    completed: "已完成",
    Inventory_btn: "库存",
    back_btn: "返回",
    discount_btn: "折扣",
    disable_btn: "禁用",
    enable_btn: "启用",
    grade_btn: "等级",
  },
  //公共提示
  commonMsg: {
    select_data_manipulated: "请选择需要操作的数据",
    please_enter_categoryname: "请输入分类名称",
    through_the_audit: "您确认要通过审核吗？",
    rejection_review: "您确认要驳回审核吗？",
    void_review: "您确认要作废审核吗？",
    want_to_delete: "您确认要删除吗？",
  },
  //商品
  goods: {
    id: "商品ID",
    product_id: "产品ID",
    commodity_information: "商品信息",
    category_id: "分类ID",
    commodity_picture: "商品图片",
    goods_sn: "商品编号",
    name: "商品名称",
    secondary_name: "二级商品名称",
    description: "描述",
    sort: "排序",
    status: "状态",
    off_shelf: "下架",
    on_shelf: "上架",
    specification_settings: "规格设置",
    specification: "规格",
    single_spec: "单规格",
    multi_spec: "多规格",
    price: "价格",
    cost_price: "成本价格",
    freight: "运费",
    specification_name: "规格名称",
    specification_val: "规格值",
    use_the_sign_to_split_multiple_values_for_example_white_black:
      "多个值请使用#符号分割，例：白色#黑色",
    add_new_specification: "添加新规格",
    generate_specification_list_immediately: "立即生成规格列表",
    add_specification_value: "添加规格值",
    parameter_name: "参数名",
    parameter_value: "参数值",
    specification_name_already_exists: "规格名称已存在，请直接添加规格",
    goods_sku_id: "SKU编码",
    custom_parameters: "自定义参数",
    add_parameter: "新增参数",
    product_details: "商品详情",
    previous: "上一步",
    next: "下一步",
    submit: "提交",
    search_goods_name: "商品名称",
    search_goods_name_placeholder: "请输入商品名称",
    search_goods_category: "商品分类",
    search_goods_category_placeholder: "请选择分类",
    number:"数量",
    inventory:"库存",
  },
  table: {
    id: "ID",
    address: "地址",
    secondary_address: "二级地址",
    main_warehouse: "是否主仓库",
    type: "类型",
    sort: "排序",
    category_name: "分类名称",
    goods_sn: "商品编码",
    name: "名称",
    product_name: "产品名称",
    price: "价格",
    cost_price: "原价",
    show_image: "缩略图",
    stock: "库存",
    desc: "描述",
    sell_nums: "销量",
    status: "状态",
    order_status: "订单状态",
    is_hidden: "是否隐藏",
    warning_stock: "库存预警值",
    warehouse_name: "仓库名称",
    from_warehouse_name: "来源仓库名称",
    to_warehouse_name: "仓库名称",
    receipt_date: "入库时间",
    receipt_type: "入库类型",
    receipt_name: "入库名称",
    outbound_date: "出库时间",
    outbound_type: "出库类型",
    outbound_name: "出库名称",
    remark: "备注",
    level: "级别",
    quota: "配额",
    operation: "操作",
    en_name: "英文名称",
    create_time: "创建时间",
    update_time: "更新时间",
    delete_time: "删除时间",
    title: "标题",
    begin_time: "开始时间",
    end_time: "结束时间",
    date: "时间",
    type_desc: "类型描述",
    unpaid: "未支付",
    paid: "已支付",
    NotInvoiced: "未开票",
    Invoiced: "已开票",
    reason:"原因",
    operation_time:"操作时间",
  },
  console: {
    today_under_the_singular: "今日下单数",
    yesterday_under_the_singular: "昨日下单数",
    today_s_sales_amount: "今日销售金额",
    yesterday_s_sales_amount: "昨日销售金额",
    amount_received_today: "今日到账金额",
    amount_received_yesterday: "昨日到账金额",
    amount_not_received_today: "今日未到账金额",
    amount_not_received_yesterday: "昨日未到账金额",
    daily_order_volume_statistics: "每日订单量统计",
  },
  category: {
    category_name: "分类名称",
    sort: "排序",
    status: "状态",
    select_the_icon: "选中icon",
  },
  avtivity: {
    title: "活动标题",
    type: "类型",
    hot_sale: "热销",
    promotion: "促销",
    activity_cover: "活动封面",
    description: "描述",
    start_time: "开始时间",
    end_time: "结束时间",
    active_goods_list: "活动商品列表",
    select_a_list_of_items_to_import: "选择导入的商品列表",
    active_inventory: "活动库存",
    price: "价格",
    open_activity: "您确认要开启活动吗?",
    close_activity: "您确认要关闭活动吗?",
    
  },
  warehouse: {
    name: "仓库名称",
    address: "地址",
    warning_stock: "预警库存",
    desc: "描述",
    type: "类型",
    inbound_name: "入库名",
    single_number: "单号",
    main_warehouse: "主仓库",
    sub_warehouse: "子仓库",
    receipt_time: "入库时间",
    warehousing: "入库仓库",
    warehousing_goods: "入库商品",
    receipt_quantity: "入库数量",
    select_a_list_of_items_to_import: "选择导入的商品列表",
    transfer_receipt: "调拨入库",
    sales_return_receipt: "销售退货入库",
    purchase_into_warehouse: "采购入仓",
    issue_name: "出库名",
    remarks: "备注",
    issue_date: "出库日期",
    outbound_warehouse: "出库仓库",
    outbound_goods: "出库商品",
    quantity_of_issue: "出库数量",
    details_of_issue_document: "出库单详情",
    order_review: "订单审核",
    approval_status: "审批状态",
    reason_for_rejection_or_void: "驳回或作废原因",
    sales_issue: "销售出库",
    transfer_doc_name: "调拨单名称",
    transfer_date: "调拨日期",
    transfer_warehouse: "调拨仓库",
    allocate_goods: "调拨商品",
    allocate_review: "调拨审核",
    a_warehouse:"从当前仓库",
    b_warehouse:"调往仓库",
  },
  reimburse: {
    price: "金额",
    date: "时间",
    type: "用途",
    desc: "描述",
    reasons_for_reimbursement: "因为什么什么报销",
    upload_invoice: "审核上传发票",
    file_uploaded: "文件已上传",
    daily_expenses: "日常开销",
  },
  statistics: {
    order_pay_price: "订单支付金额",
    pay_price: "已支付金额",
    no_pay_price: "未支付金额",
    order_sn: "订单号",
    sale_name: "销售名称",
    nickname: "客户昵称",
    phone: "手机号",
    repayment_time: "还款时间",
    total_order_amount: "订单总金额",
    collect_price: "已收款金额",
    sub_price: "剩余款项",
    extension_time: "延期时间",
    pay_type_desc: "支付类型",
  },
  purchasing: {
    receipt_issue_details: "出入库明细",
    out_stock: "出库数量",
    in_stock: "入库数量",
    quantity: "库存",
  },
  sys: {
    basic_configuration: "基础配置",
    customer_service_telephone: "客服电话",
    phone_registration_switch: "手机号注册开关",
  },
  banner: {
    title: "标题",
    jump_link: "跳转地址",
    status: "状态",
    off_the_shelf: "下架",
    shelf: "上架",
    picture: "图片",
  },
  manager: {
    header: "头像",
    administrator_name: "管理员名称",
    account: "账户",
    phone: "手机号",
    password: "密码",
    status: "状态",
    warehouse_ids: "关联管理仓库",
    role_id: "角色名称",
    leader_id: "上级领导",
  },
  rating: {
    name: "等级名称",
    quota: "配额",
    status: "状态",
    level: "等级",
  },
  role: {
    name: "角色名称",
    permission_management: "权限管理",
    status: "状态",
    desc: "描述",
    type:"类型",
    other:"其他",
    sales:"销售",
    sales_manager:"销售经理",
    finance:"财务",
  },
  address: {
    level_1_area: "一级地区",
    secondary_area: "二级地区",
    level_3_area: "三级地区",
    name: "地址名称",
    pid: "上级城市",
    level: "等级",
  },
  order_approval: {
    amount_greater: "金额大于",
    end: "结束",
    configuration: "配置",
    text1: "环节的审批人员",
    all_personnel: "全部人员",
    people_selected: "已选择人员",
    sales: "销售",
    sales_manager: "销售经理",
    wind_control_audit: "风控审核",
    general_manager: "总经理",
    finance: "财务",
    li: "李总",
    export_summary: "导出汇总",
    prohibit_configuration: "当前节点禁止配置",
  },
  order: {
    order_goods_export: "订单商品导出",
    export_excel: "导出Excel",
    details: "详情",
    shipment: "发货",
    audit: "审核",
    invoicing: "开具发票",
    receipt: "收款",
    remarks: "备注",
    order_date: "下单日期",
    home_user: "归属用户",
    outbound_warehouse: "出库仓库",
    user_address:"用户地址",
    All: '全部',
    toBeReviewed: '待审核',
    confirmed: '已确认',
    payment: '付款',
    treatLibrary: '待出库',
    HandleCancel: '取消',
    accomplish: '已完成',
    payment_type:"支付类型",
    first_payment_after_goods:"先款后货",
    goods_before_payment:"先货后款",
    repayment_time:"还款时间",
    buy_goods:"选购商品",
    select_a_list_of_items_to_import: "选择导入的商品列表",
    customer_information: "客户信息",
    customer_name: "客户姓名",
    contact_phone: "联系电话",
    contact_email: "邮箱",
    sales_name: "销售名称",
    picking_warehouse: "提货仓库",
    picking_code: "提货码",
    payment_amount: "付款金额",
    stay: "待",
    passed: "已通过",
    rejected: "已驳回",
    reviewer: "审核人",
    reason_for_rejection: "驳回原因",
    order_review: "订单审核",
    approval_status: "审批状态",
    receipt_amount: "收款金额",
    received_amount: "已收款金额",
    uncollected_amount: "未收款金额",
    cash: "现金",
    bank_card: "银行卡",
    start_time: "开始时间",
    deadline: "截止时间",
    confirm_to_ship: "确认要发货吗?",
    whether_to_pay: "是否支付",
    shipment_note:"发货单",
  },
  member:{
    gender:"性别",
    quota:"信用额度",
    level_name:"客户等级",
    discount_price:"折扣价格",
    province_name:"区域",
    sale_name:"销售人员",
    sub_price:"欠款金额",
    total_price:"下单总金额",
    total:"下单总数",
    phone:"电话",
    email:"邮箱",
    name:"用户名称",
    avatar:"头像",
    pwd:"密码",
    no_grade:"无等级",
    male:"男",
    female:"女",
    normal:"正常",
    disable:"禁用",
    modify_member_discount_amount:"修改会员折扣金额",
    open_member: "您确认要启用会员吗?",
    close_member: "您确认要禁用会员吗?",
  }
};
