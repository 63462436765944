/*
 * @Author: 刘逛 180768871@qq.com
 * @Date: 2024-10-09 16:25:32
 * @LastEditors: 刘逛 180768871@qq.com
 * @LastEditTime: 2024-10-10 11:45:21
 * @FilePath: /admin/src/utils/haspermissions.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
const bs_user_info = localStorage.getItem('bs_user_info') || '{}';
console.log(JSON.parse(bs_user_info));
let role_permission = JSON.parse(bs_user_info).role_permission || [];

export default (Vue) => {
    Vue.directive('has', {
        mounted(el, binding) {
            const requiredPermission = parseInt(binding.value); // 获取当前按钮的权限
            console.log("当前权限ID为", requiredPermission);
            checkPermission(el, requiredPermission);
        }
    });

    function checkPermission(el, requiredPermission) {
        const bs_user_info = localStorage.getItem('bs_user_info');
        role_permission = JSON.parse(bs_user_info).role_permission || [];
        console.log(JSON.parse(bs_user_info).is_admin);
        if (!role_permission.includes(requiredPermission) && JSON.parse(bs_user_info).is_admin !== 1) { // 判断当前用户的权限是否包含当前按钮的权限
            el.parentNode && el.parentNode.removeChild(el);
        }
    }

    // 监听权限信息的变化，实时更新前端权限状态
    window.addEventListener('storage', function(event) {
        if (event.key === 'bs_user_info') {
            role_permission = JSON.parse(event.newValue).role_permission || [];
            const buttons = document.querySelectorAll('[v-has]');
            buttons.forEach(button => {
                const requiredPermission = parseInt(button.getAttribute('v-has'));
                checkPermission(button, requiredPermission);
            });
        }
    });
};