import axios from "axios";
import router from '/src/router/router'
// 请求拦截
axios.interceptors.request.use(
  (config) => {
    // 1. 这个位置就请求前最后的配置
    // 2. 当然你也可以在这个位置 加入你的后端需要的用户授权信息
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截
axios.interceptors.response.use(
  (response) => {
    // 请求成功
    if(response.headers.token){
      localStorage.setItem("token",response.headers.token);
    }
    if(response.data.code === 401) {
      router.push('/')
    }
    return response.data;
  },
  (error) => {
    // 请求失败
    if (error && error.response) {
      switch (error.response.status) {
        case 400:
          // 对400错误您的处理\
          break;
        default:
          // 如果以上都不是的处理
          return Promise.reject(error);
      }
    }
  }
);
