import { createI18n } from 'vue-i18n'
import zhCN from '../language/zh-CN'
import enUS from '../language/en_US'

const i18n = createI18n({
  legacy: false,
  locale: localStorage.erp_lang || 'zh-cn', // 默认显示语言
  messages: {
    'zh-cn': zhCN,
    'en-us': enUS
  }
})

export default i18n
