/*
 * @Author: 刘逛 180768871@qq.com
 * @Date: 2023-04-26 09:35:41
 * @LastEditors: 刘逛 180768871@qq.com
 * @LastEditTime: 2024-11-21 11:51:42
 * @FilePath: /admin/src/main.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { createApp } from 'vue'
import router from '@/router/router.js'
import ElementPlus from 'element-plus'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
 import zh_cn from 'element-plus/es/locale/lang/zh-cn'
import en from 'element-plus/es/locale/lang/en'
import 'element-plus/dist/index.css'
import "./assets/main.css";
import './styles/element/index.scss'
import hasPermission from './utils/haspermissions'

import App from './App.vue'

//api接口
import api from './http/api'
import http from './http/http'
import './http/axios'

//国际化
import i18n from './language'

//打印
import print from 'vue3-print-nb'


const app = createApp(App)
// 对路由 进行全局注册
app.config.globalProperties.$router = router
// 对后端接口 进行全局注册
app.config.globalProperties.$api = api
// 对请求方式 进行全局注册
app.config.globalProperties.$http = http


app.use(router)
app.use(i18n)
app.use(ElementPlus,{
    locale:  localStorage.getItem("erp_lang") == 'en-us' ? en : zh_cn
})
app.use(print)
app.use(hasPermission)

if (process.env.NODE_ENV == 'development') {
    app.config.devtools = true;
} else {
    app.config.devtools = false;
}

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
  }

  
app.mount('#app')
